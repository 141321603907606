import {mapActions, mapGetters} from "vuex";

export default {
    props: ['orderData'],
    data(){
      return{
          templateText:'',
          activeTemplate:0,
          prevActiveTemplate:0,
          textTemplateList:[
              {
                  template:'{order_id}',
                  text:''
              },
              {
                  template:'{buyer_fio}',
                  text:''
              },
              {
                  template:'{buyer_f}',
                  text:''
              },
              {
                  template:'{buyer_i}',
                  text:''
              },
              {
                  template:'{buyer_o}',
                  text:''
              },
              {
                  template:'{total}',
                  text:''
              },
              {
                  template:'{products}',
                  text:''
              },
              {
                  template:'{ttn}',
                  text:''
              },
          ]
      }
    },
    computed:{
      ...mapGetters({
          smsTemplates:'templates/smsTemplates',
          smsBalance:'templates/smsBalance',
      })
    },
    watch:{
        templateText(){
            this.validateText()
        }
    },
    created() {
        this.getSmsTemplates();
        this.getDataForTextTemplates()
        this.getSmsBalance();
    },
    methods:{
        sendSMS(){
            const payload = {
                "phone": "38"+this.orderData.phone,
                "text" : this.templateText,
                "id":this.orderData.id,
            }
            this.sendSMSTemplate(payload).then(res=>{
                if(res.result){
                    this.$toasted.success('Смс успешно отправлено', {
                        duration: 3000,
                    })
                    this.closePopup();
                }else {
                    this.$toasted.error(res.data, {
                        duration: 3000,
                    })
                }
            })

        },
        showActiveTemplate(id){
            let activeTemplate = this.smsTemplates.data.filter(item=>item.id === id)
            activeTemplate = activeTemplate[0];
            this.changeActiveTemplate(activeTemplate)
        },
        getActiveTemplate(item){
            this.prevActiveTemplate = item.id
        },
        changeActiveTemplate(item){
            this.activeTemplate = item.id;
            this.templateText = item.text
        },
        validateText(){
            this.textTemplateList.map(item=>{
                if(this.templateText.search(item.template) !== -1){
                    this.templateText = this.templateText.replace(item.template, item.text);
                }
            })
        },
        closePopup(){
            this.$emit('closePopup');
        },
        getDataForTextTemplates(){
            const customerName = this.orderData.customer_name.split(' ');
            let price = 0;
            let products = 0;
            this.orderData.products.map(item=>{
                price += item.price * item.quantity;
                products += item.title + ', ';
            })
            this.textTemplateList.map(item=>{
                if(item.template === '{order_id}'){item.text = this.orderData.order_id ? this.orderData.order_id : ''}
                else if(item.template === '{buyer_fio}'){item.text = this.orderData.customer_name ? this.orderData.customer_name : ''}
                else if(item.template === '{buyer_f}'){item.text = customerName[0] ? customerName[0] : ''}
                else if(item.template === '{buyer_i}'){item.text = customerName[1] ? customerName[1] : ''}
                else if(item.template === '{buyer_o}'){item.text = customerName[2] ? customerName[2] : ''}
                else if(item.template === '{total}'){item.text = price ? price : 0}
                else if(item.template === '{products}'){item.text = products ? products : ''}
                else if(item.template === '{ttn}'){item.text = this.orderData.ttn ? this.orderData.ttn : ''}
            })
        },
        ...mapActions({
            getSmsTemplates:'templates/getSmsTemplates',
            getSmsBalance:'templates/getSmsBalance',
            sendSMSTemplate:'templates/sendSMSTemplate',
        })
    }
}